import React, { useState, useEffect } from 'react'
import { LamePage } from './LamePage'
import { Button, CircularProgress } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { TourItem } from './components/TourItem';
import { UpdateTour } from './components/UpdateTour';
import { TourHeader } from './components/TourHeader';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';

export const Tour = () => {
  const { isAuthenticated } = useAuth0();
  const [tour, setTour] = useState([]);
  const [upcomingTourDates, setUpcomingTourDates] = useState([]);
  const [addTourDate, setAddTourDate] = useState(false);
  const matches = useMediaQuery('(max-width:400px)');

  useEffect(() => {
    const getTour = async () => {
      try {
        const res = await axios.get('https://api.lamegenie.com/api/tour');
        console.log("Fetched Tour Data:", res.data); // Debugging
        setTour(res.data);
      } catch (error) {
        console.error("Error fetching tour dates:", error);
      }
    };
    getTour();
  }, []); // Runs once on mount

  useEffect(() => {
    if (tour.length > 0) {
      const now = new Date();
      now.setHours(0, 0, 0, 0); // Set to start of today in local time

      const sortedUpcoming = tour
        .map(item => {
          const dateParts = item.date.split('/');
          if (dateParts.length !== 3) return null; // Invalid date format
          
          // Convert MM/DD/YYYY to a Date object in local time
          const dateObj = new Date(
            Date.UTC(dateParts[2], dateParts[0] - 1, dateParts[1], 23, 59, 59) // Ensure full day inclusion
          );

          return !isNaN(dateObj) ? { ...item, dateObj } : null;
        })
        .filter(item => item && item.dateObj >= now) // Compare without time zone shifts
        .sort((a, b) => a.dateObj - b.dateObj); // Sort by date

      console.log("Upcoming Tour Dates:", sortedUpcoming); // Debugging
      setUpcomingTourDates(sortedUpcoming);
    }
  }, [tour]); // Runs when `tour` changes

  const handleAddTourDateClick = (e) => {
    e.preventDefault();
    setAddTourDate(true);
  };

  const refreshTour = async () => {
    try {
      const res = await axios.get('https://api.lamegenie.com/api/tour');
      setTour(res.data);
    } catch (error) {
      console.error("Error refreshing tour dates:", error);
    }
    setAddTourDate(false);
  };

  const addTourDateSection = () => {
    return addTourDate ? (
      <UpdateTour refreshTour={refreshTour} />
    ) : (
      <Button onClick={handleAddTourDateClick} variant="contained" color="primary">
        Add Tour Date
      </Button>
    );
  };

  return (
    <LamePage otherClass='tour'>
      {tour.length !== 0 ? (
        <div>
          <h1>Tour</h1>
          {!matches && <TourHeader />}
          {upcomingTourDates.length > 0 ? (
            upcomingTourDates.map((item, index) => (
              <TourItem
                key={index}
                date={item.date} // Keep original string for display
                venue={item.venue}
                info={item.info}
                ticketLink={item.ticketLink}
                id={item._id}
                refreshTour={refreshTour}
              />
            ))
          ) : (
            <h1>No upcoming tour dates</h1>
          )}
          {isAuthenticated && addTourDateSection()}
        </div>
      ) : (
        <div className='d-flex w-100 justify-content-center align-items-center' style={{ height: '50vh' }}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </LamePage>
  );
};
